import React from 'react';
import { Col, Grid, Row } from "react-flexbox-grid";
import { ReactComponent as Icon1 } from '../../svg/text_icons/icon1.svg';
import { ReactComponent as Icon2 } from '../../svg/text_icons/icon2.svg';
import { ReactComponent as Icon3 } from '../../svg/text_icons/icon3.svg';
import { ReactComponent as Icon4 } from '../../svg/text_icons/icon4.svg';

import './WhyUsFull.css'; // Import the CSS file

// Reusable Icon Component
const IconBox = ({ IconComponent }) => (
    <Col lg={3} className="icon-container">
        <Row className="icon-wrapper">
            <IconComponent className="icon-style" />
        </Row>
    </Col>
);

// Reusable Feature Component
const FeatureBox = ({ title, description }) => (
    <Col lg={4} className="feature-container__wrapper">
        <div className="feature-container">
            <Row>
                <div className="feature-highlight"></div>
            </Row>
            <Row className="feature-content">
                <Col lg={12}>
                    <h3>{title}</h3>
                    <div dangerouslySetInnerHTML={{__html: description}}></div>
                </Col>
            </Row>
        </div>
    </Col>
);

const featureData = [
    {
        title: "Financial",
        description: `
        <ul>
            <li><strong>Cost Savings:</strong> Outsourcing security to HoundBytes reduces expenses on staffing, hardware, and training for in-house teams.</li>
            <li><strong>Flexible Pricing:</strong> We offer pricing options tailored to fit your budgets.</li>
            <li><strong>Increased Profitability:</strong> Our clients improve financial performance by focusing on their core business activities.</li>
        </ul>
        `
    },
    {
        title: "Technical",
        description: `
        <ul>
            <li><strong>Advanced Technology:</strong> HoundBytes invests in cutting-edge technology to offer top-tier cybersecurity, protecting clients against the latest threats.</li>
            <li><strong>Customizable Solutions:</strong> Our MDR services are tailored to meet each client's unique security needs.</li>
            <li><strong>Scalable Services:</strong> As businesses grow, our services scale effortlessly to ensure continuous protection.</li>
        </ul>
        `
    },
    {
        title: "Security",
        description: `
        <ul>
            <li><strong>Proven Expertise:</strong> Our experienced security team offers expert threat management, giving clients peace of mind.</li>
            <li><strong>Comprehensive Coverage:</strong> We provide 24/7 monitoring, incident response, threat intelligence, and security management.</li>
            <li><strong>Timely Incident Response:</strong> Our experts respond swiftly to security incidents, minimizing business disruptions.</li>
            <li><strong>Compliance Support:</strong> HoundBytes helps clients meet regulatory requirements and stay compliant with industry standards.</li>
        </ul>
        `
    },
    {
        title: "NIS 2 Directive Compliance",
        description: `By working with us, HoundBytes offers dedicated, free of charge, service to ensure compliance with the NIS 2 Directive. We assess practices, identify gaps, provide recommendations, and offer ongoing support, leveraging our cybersecurity expertise to meet NIS 2 requirements.`
    },
    {
        title: "SDG #9 Compliance",
        description: `Free of charge, we actively help our clients achieve Sustainable Development Goal #9 by enhancing cybersecurity for resilient infrastructure. Our audits and assessments secure against evolving threats, supporting sustainable operations and industrialization, while protecting intellectual property and sensitive data.`
    },
    {
        title: "HoundBytes’ Philosophy",
        description: `At HoundBytes, we take pride in our philosophy: <strong>We are your cybersecurity department, not just another MDR service vendor.</strong> This means we will assist you, at <strong>no additional cost</strong>, in all cybersecurity aspects such as: firewall and WAF verification and improvement, software architecture security consulting, security policies and procedures design, etc. We are your Partner, Innovator and Defender.`
    },
];

const WhyUsFull = () => {
    return (
        <div className="why_us">
            <div className="container">
                <Grid fluid>
                    <Row className="why-us-icons-row">
                        {[Icon1, Icon2, Icon3, Icon4].map((Icon, idx) => (
                            <IconBox key={idx} IconComponent={Icon} />
                        ))}
                    </Row>

                    <div className="gradient-top-to-bottom">
                        <Row className="feature-row">
                            {featureData.map((feature, idx) => (
                                <FeatureBox key={idx} title={feature.title} description={feature.description} />
                            ))}
                        </Row>
                    </div>
                </Grid>
            </div>
        </div>
    );
};

export default WhyUsFull;

import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import PrivacyP from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import KeyFeatures from "./pages/KeyFeatures";
import Menu from "./components/menu/Menu";
import React, {useState} from "react";
import WhyUs from "./pages/WhyUs";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import MenuBottom from "./components/menu/MenuBottom";

import './apm';

function App() {

    const [menuWhiteTheme, setMenuWhiteTheme] = useState(true);

    return (
        <div className="App">

            <img src="%PUBLIC_URL%/hero.webp" alt="" style={{ display: "none" }} />
        <BrowserRouter>
            <ScrollToTop />
            <Menu menuWhiteTheme={menuWhiteTheme}/>
            <MenuBottom/>
            <Routes>
                <Route path="/" element={<Home setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
                <Route path="/key_features" element={<KeyFeatures setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
                <Route path="/why_us" element={<WhyUs setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
                <Route path="/team" element={<Team setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
                <Route path="/contact" element={<Contact setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
                <Route path="/privacy" element={<PrivacyP setMenuWhiteTheme={setMenuWhiteTheme}/>}/>
            </Routes>
        </BrowserRouter>


    </div>
  );
}

export default App;
